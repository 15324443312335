<template>
    <div class="home">
        <a-row>
            <a-col :span="16">
                <a-card class="card" title="防盗记录">
                    <template #extra>
                        <a-button>
                            <RedoOutlined />刷新
                        </a-button>
                    </template>
                    <a-table class="table" :dataSource="AlarmHistory" :columns="alarm_columns">

                    </a-table>
                </a-card>
            </a-col>
            <a-col :span="8">
                <a-card class="card" title="防盗器">
                    <template #extra>
                        <a-button>
                            <RedoOutlined />刷新
                        </a-button>
                    </template>
                    <a-table class="table" childrenColumnName="Antennas" :dataSource="Raeders"
                        :columns="reader_columns">

                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key == 'Enable'">
                                <a-switch v-model:checked="record.Enable" :disabled="true" />
                            </template>

                            <template v-else-if="column.key === 'Connect'">
                                <span>
                                    <a-tag :color="record.Connect ? 'green' : 'volcano'">
                                        {{ record.Connect ? '连接' : '断开' }}
                                    </a-tag>
                                </span>
                            </template>
                        </template>
                    </a-table>
                </a-card> <a-card class="card" title="标签移动检测">
                    <template #extra>
                        <a-button>
                            <RedoOutlined />刷新
                        </a-button>
                    </template>
                    <a-table class="table" :dataSource="MoveAnalysis" :columns="move_columns">

                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key == 'Enable'">
                                <a-switch v-model:checked="record.Enable" :disabled="true" />
                            </template>

                            <template v-else-if="column.key === 'Connect'">
                                <span>
                                    <a-tag :color="record.Connect ? 'green' : 'volcano'">
                                        {{ record.Connect ? '连接' : '断开' }}
                                    </a-tag>
                                </span>
                            </template>
                        </template>
                    </a-table>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { RedoOutlined } from '@ant-design/icons-vue';
// 你可以在这里添加组件的逻辑
export default {
    name: 'HomeView',
    // 组件的数据、方法、计算属性等
    setup() {
        return {
            reader_columns: [
                {
                    title: '名称',
                    dataIndex: 'Name',
                    key: 'Name',
                },
                {
                    title: '连接状态',
                    dataIndex: 'Connect',
                    key: 'Connect',
                },
                {
                    title: '开关',
                    dataIndex: 'Enable',
                    key: 'Enable',
                }
            ],
            alarm_columns: [
                {
                    title: '时间',
                    dataIndex: 'DateTime',
                    key: 'DateTime',
                },
                {
                    title: '标签',
                    dataIndex: 'EPC',
                    key: 'EPC',
                },
                {
                    title: '位置',
                    dataIndex: 'Reader',
                    key: 'Reader',
                },
            ],
            move_columns: [
                {
                    title: '名称',
                    dataIndex: 'Name',
                    key: 'Name',
                },
                {
                    title: '移动方向',
                    dataIndex: 'Dir',
                    key: 'Dir',
                },
                {
                    title: '开关',
                    dataIndex: 'Enable',
                    key: 'Enable',
                },
            ],
        };
    },
    computed: {
        ...mapState([
            'Raeders',
            'AlarmHistory',
            'MoveAnalysis'
        ]),
    },
    components: {
        RedoOutlined
    },
};
</script>

<style scoped>
/* 在这里添加样式，scoped属性确保样式只应用于当前组件 */
.card {
    margin: 8px;
    /* 添加更多样式 */
}
</style>