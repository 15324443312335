<template>
    <div class="home">
        <a-row>
            <a-col :span="24">
                <a-card class="card" title="操作记录">
                    <template #extra>
                        <a-button>
                            <SearchOutlined />筛选
                        </a-button>
                    </template>
                    <a-table class="table" :dataSource="ActionHistory" :columns="columns">

                    </a-table>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { SearchOutlined } from '@ant-design/icons-vue';
// 你可以在这里添加组件的逻辑
export default {
    name: 'ActionHistoryView',
    // 组件的数据、方法、计算属性等
    setup() {
        return {
            columns: [
                {
                    title: '时间',
                    dataIndex: 'DateTime',
                    key: 'DateTime',
                },
                {
                    title: '操作',
                    dataIndex: 'ActionName',
                    key: 'ActionName',
                },
                {
                    title: '详细',
                    dataIndex: 'Detail',
                    key: 'Detail',
                },
            ],
        };
    },
    computed: {
        ...mapState([
            'ActionHistory'
        ]),
    },
    components: {
        SearchOutlined
    },
};
</script>

<style scoped>
/* 在这里添加样式，scoped属性确保样式只应用于当前组件 */
.card {
    margin: 8px;
    /* 添加更多样式 */
}
</style>