<template>
    <div class="home">
        <a-modal v-model:open="openCreateAntenna" title="添加天线" @ok="handleOk">
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
        </a-modal>
        <a-modal v-model:open="openEditAntenna" title="修改天线设置" @ok="handleOk">
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
        </a-modal>
        <a-row>
            <a-col :span="24">
                <a-card class="card" title="标签移动检测">
                    <template #extra>
                        <a-button>
                            <PlusOutlined />添加
                        </a-button>
                        <a-divider type="vertical" />
                        <a-button>
                            <RedoOutlined />刷新
                        </a-button>
                    </template>
                    <a-table class="table" :dataSource="MoveAnalysis"
                        :columns="move_columns">

                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key == 'Enable'">
                                <a-switch v-model:checked="record.Enable" />
                            </template>
                            <template v-else-if="column.key === 'Action' && record.IsReader">
                                <span>
                                    <a>添加天线</a>
                                    <a-divider type="vertical" />
                                    <a>修改</a>
                                    <a-divider type="vertical" />
                                    <a-popconfirm title="确定删除吗？" ok-text="是" cancel-text="否" @confirm="confirm"
                                        @cancel="cancel">
                                        <a>删除</a>
                                    </a-popconfirm>
                                </span>
                            </template>
                            <template v-else-if="column.key === 'Action' && !record.IsReader">
                                <span>
                                    <a>修改</a>
                                    <a-divider type="vertical" />
                                    <a-popconfirm title="确定删除吗？" ok-text="是" cancel-text="否" @confirm="confirm"
                                        @cancel="cancel">
                                        <a>删除</a>
                                    </a-popconfirm>
                                </span>
                            </template>
                            <template v-else-if="column.key === 'Parameter' && record.IsReader">
                                <span>
                                    IP: {{ record.IP }}
                                </span>
                            </template>
                            <template v-else-if="column.key === 'Parameter' && !record.IsReader">
                                <span>
                                    读功率: {{ record.ReadPower }}
                                </span>
                            </template>
                        </template>
                    </a-table>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons-vue';
// 你可以在这里添加组件的逻辑
export default {
    name: 'MoveConfigView',
    // 组件的数据、方法、计算属性等
    setup() {
        return {
            move_columns: [
                {
                    title: '名称',
                    dataIndex: 'Name',
                    key: 'Name',
                },
                {
                    title: '灵敏度',
                    dataIndex: 'Sensitivity',
                    key: 'Sensitivity',
                },
                {
                    title: '移动方向',
                    dataIndex: 'Dir',
                    key: 'Dir',
                },
                {
                    title: '开关',
                    dataIndex: 'Enable',
                    key: 'Enable',
                },
                {
                    title: '操作',
                    key: 'Action',
                },
            ],
        };
    },
    computed: {
        ...mapState([
            'MoveAnalysis',
        ]),
    },
    components: {
        PlusOutlined,
        RedoOutlined
    },
};
</script>

<style scoped>
/* 在这里添加样式，scoped属性确保样式只应用于当前组件 */
.card {
    margin: 8px;
    /* 添加更多样式 */
}
</style>