<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" collapsible>
      <div class="logo">RFID门店防盗</div>
      <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
        <a-menu-item key="/" v-on:click="goto('/')">
          <pie-chart-outlined />
          <span>主页</span>
        </a-menu-item>
        <a-menu-item key="/readerconfig" v-on:click="goto('readerconfig')">
          <pie-chart-outlined />
          <span>防盗器配置</span>
        </a-menu-item>
        <a-menu-item key="/whitelist" v-on:click="goto('whitelist')">
          <pie-chart-outlined />
          <span>白名单</span>
        </a-menu-item>
        <a-menu-item key="/alarmhistory" v-on:click="goto('alarmhistory')">
          <pie-chart-outlined />
          <span>防盗记录</span>
        </a-menu-item>
        <a-menu-item key="/moveconfig" v-on:click="goto('moveconfig')">
          <pie-chart-outlined />
          <span>标签移动分析</span>
        </a-menu-item>
        <a-menu-item key="/actionhisotry" v-on:click="goto('actionhisotry')">
          <pie-chart-outlined />
          <span>操作记录</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <div class="panel">
          <div class="time">
            <span id="datetime">执行时间:&nbsp;&nbsp;</span>
          </div>
        </div>
      </a-layout-header>
      <a-layout-content style="margin: 0 16px">
        <router-view />
      </a-layout-content>
      <a-layout-footer style="text-align: center">
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { PieChartOutlined } from '@ant-design/icons-vue';
export default {
  name: 'App',
  data() {
    return {
      collapsed: ref(false),
      selectedKeys: [this.$route.path],
    }
  },
  mounted() {
    console.log('mounted')
    setInterval(function () {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      let str = `${year}-${month}-${day}&nbsp;&nbsp;${hours}:${minutes}:${seconds}`;
      document.getElementById('datetime').innerHTML = str
    }, 600);
  },
  computed: {
    ...mapState([
      'HelloWorldStr',
    ]),
  },
  components: {
    PieChartOutlined
  },
  methods: {
    goto(path) {
      this.$router.push(path);
    }
  },
  watch: {
    $route(to) {
      this.selectedKeys = [to.path]
    }
  }
}
</script>

<style>
.logo {
  height: 32px;
  margin: 16px;
  color: white;
  font-size: 22px;
  text-align: center;
  font-family: fangsong;
  line-height: 30px;
}

.panel {
  position: absolute;
  top: 10px;
  right: 30px;
  height: 40px;
  width: 320px;
  border-radius: 10px;
  line-height: 40px;
  color: rgb(108, 108, 108);
  font-size: 18px;
  padding-left: 20px;
  text-align: right;
}


.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>
