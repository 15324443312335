<template>
    <div class="home">
        <a-row>
            <a-col :span="24">
                <a-card class="card" title="白名单">
                    <template #extra>
                        <a-button>
                            <PlusOutlined />添加
                        </a-button>
                        <a-divider type="vertical" />
                        <a-button>
                            <SearchOutlined />筛选
                        </a-button>
                    </template>
                    <a-table class="table" :dataSource="WhiteList" :columns="columns">

                    </a-table>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons-vue';
// 你可以在这里添加组件的逻辑
export default {
    name: 'WhiteListView',
    // 组件的数据、方法、计算属性等
    setup() {
        return {
            columns: [
                {
                    title: '标签',
                    dataIndex: 'EPC',
                    key: 'EPC',
                },
                {
                    title: '屏蔽位置',
                    dataIndex: 'Reader',
                    key: 'Reader',
                },
            ],
        };
    },
    computed: {
        ...mapState([
            'WhiteList'
        ]),
    },
    components: {
        PlusOutlined,
        SearchOutlined
    },
};
</script>

<style scoped>
/* 在这里添加样式，scoped属性确保样式只应用于当前组件 */
.card {
    margin: 8px;
    /* 添加更多样式 */
}
</style>