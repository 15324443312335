import { createStore } from 'vuex';

export default createStore({
    state: {
        // 初始化状态
        Raeders: [
            {
                key: 1,
                ReaderId: 1,
                Name: '通道1',
                IP: '192.168.0.1',
                Enable: true,
                Connect: true,
                IsReader: true,
                Antennas: [
                    {
                        Name: '天线1',
                        ReadPower: 30,
                        Enable: 'On',
                    }
                ]
            },
            {
                key: 2,
                ReaderId: 2,
                Name: '通道2',
                IP: '192.168.0.2',
                Enable: false,
                Connect: false,
                IsReader: true,
                Antennas: [
                    {
                        Name: '天线1',
                        ReadPower: 30,
                        Enable: 'On',
                    },
                    {
                        Name: '天线2',
                        ReadPower: 30,
                        Enable: 'On',
                    }
                ]
            },
        ],
        AlarmHistory: [
            {
                DateTime: '2024-11-01 08:30:00',
                EPC: '1D392980D860',
                Reader: '通道1'
            }
        ],
        WhiteList: [
            {
                EPC: '1D392980D860',
                Reader: '通道1'
            },
            {
                EPC: '20828FE744FA',
                Reader: '所有通道'
            }
        ],
        MoveAnalysis: [
            {
                Name: '通道1',
                Sensitivity: '80%',
                Enable: 1,
                Dir: '天线1 -> 天线2 -> 天线3'
            },
            {
                Name: '通道2',
                Sensitivity: '80%',
                Enable: 0,
                Dir: '天线1 -> 天线2 -> 天线3'
            }
        ],
        ActionHistory: [
            {
                DateTime: '2024-11-01 08:30:00',
                ActionName: '打开防盗器',
                Detail: '打开防盗器-通道1'
            },
            {
                DateTime: '2024-11-01 08:35:00',
                ActionName: '关闭防盗器',
                Detail: '关闭防盗器-通道1'
            }
        ]
    },
    mutations: {
        // 更改状态的逻辑
    },
    actions: {
        // 提交 mutation 的逻辑
    },
    getters: {
        // 获取状态的逻辑
    }
});