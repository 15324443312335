import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import WhiteList from '@/views/WhiteList.vue';
import ReaderConfig from '@/views/ReaderConfig.vue';
import MoveConfig from '@/views/MoveConfig.vue';
import AlarmHistory from '@/views/AlarmHistory.vue';
import ActionHistory from '@/views/ActionHistory.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/whitelist',
        name: 'WhiteList',
        component: WhiteList
    },
    {
        path: '/readerconfig',
        name: 'ReaderConfig',
        component: ReaderConfig
    },
    {
        path: '/alarmhistory',
        name: 'AlarmHistory',
        component: AlarmHistory
    },
    {
        path: '/moveconfig',
        name: 'MoveConfig',
        component: MoveConfig
    },
    {
        path: '/actionhisotry',
        name: 'ActionHistory',
        component: ActionHistory
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;